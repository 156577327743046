import axios from 'axios';
import { REQUEST, SUCCESS, FAILURE } from '../shared/reducers/action-type.util';
import { API_URL, getToken } from '../config/constants';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';
import appointmentReducer from './appointment.reducer';
import { MEDICAL_HISTORY_STATUS } from '../config/constants';

const UN_ASSIGNED_LIST_KEY = -1;

export const ACTION_TYPES = {
  FETCH_WEEK_DETAIL: 'video/FETCH_WEEK_DETAIL',
  FETCH_WEEK_DETAIL_OFFLINE: 'video/FETCH_WEEK_DETAIL_OFFLINE',
  FETCH_DOCTORS: 'video/FETCH_DOCTORS',
  FETCH_DOCTORS_BY_SLOT_ID: 'video/FETCH_DOCTORS_BY_SLOT_ID',
  FETCH_MEDICAL_HISTORY: 'video/FETCH_MEDICAL_HISTORY',
  FETCH_APPOINTMENTS: 'video/FETCH_APPOINTMENTS',
  FETCH_CONSULTATION_DETAILS: 'video/FETCH_CONSULTATION_DETAILS',
  INITIATE_VIDEO_CALL: 'video/INITIATE_VIDEO_CALL',
  ASSIGN_DOCTOR_FOR_APPOINTMENT: 'video/ASSIGN_DOCTOR_FOR_APPOINTMENT',
  RESET_DATA: 'RESET_DATA',
  CREATE_OFFLINE_APPOINTMENT: 'video/CREATE_OFFLINE_APPOINTMENT',
  GET_OFFLINE_APPOINTMENT: 'video/GET_OFFLINE_APPOINTMENT',
  EDIT_OFFLINE_APPOINTMENT: 'video/EDIT_OFFLINE_APPOINTMENT',
  GET_PET_DETAILS: 'video/GET_PET_DETAILS',
  EDIT_PET_DETAILS: 'video/EDIT_PET_DETAILS',
  SAVE_REMINDER: 'video/SAVE_REMINDER',
  UPDATE_REMINDER: 'video/UPDATE_REMINDER',
  GET_REMINDERS: 'video/GET_REMINDERS',
  DELETE_REMINDER: 'video/DELETE_REMINDER',
  GET_REMINDER_ID: 'video/GET_REMINDER_ID',
  GET_REMINDER_TYPES: 'video/GET_REMINDER_TYPES',
  GET_SPECIALITY: 'video/GET_SPECIALITY',
  GET_QUESTIONNAIRE: 'video/GET_QUESTIONNAIRE',
  UPLOAD_DOCUMENT: 'video/UPLOAD_DOCUMENT',
  CREATE_QUESTIONNAIRE: 'video/CREATE_QUESTIONNAIRE',
  EDIT_QUESTIONNAIRE: 'video/EDIT_QUESTIONNAIRE',
  DOWNLOAD_APPOINTMENT_PDF: 'video/DOWNLOAD_APPOINTMENT_PDF'
};

const initialState = {
  payloadSaved: false,
  loading: false,
  weekDetail: [] as any,
  weekDetailOffline: [] as any,
  doctors: [] as any,
  doctorsBySlot: [] as any,
  appointments: [] as any,
  medicalHistoryResponse: [] as any,
  consultationDetails: {} as any,
  initiateVideoCallResponse: {} as any,
  assignDoctorResponse: {} as any,
  createOfflineAppointmentResponse: {} as any,
  offlineAppointmentResponse: {} as any,
  editOfflineAppointmentResponse: {} as any,
  petDetail: {} as any,
  editPetDetailResponse: {} as any,
  saveReminderResponse: {} as any,
  updateReminderResponse: {} as any,
  getRemindersResponse: {} as any,
  getReminderByIdResponse: {} as any,
  getReminderTypesResponse:{} as any,
  deleteReminder: {} as any,
  specialities: [] as any,
  questionnaireResponse: [] as any,
  uploadDocumentResponse: {} as any,
  createQuestionnaireResponse: [] as any,
  editQuestionnaireResponse: [] as any,
  createAppointmentStatus: {} as any,
  editAppointmentStatus: {} as any,
  appointmentDownloadResponse: {} as any,
  totalCount:0,
};

const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

export type VideoConsultationState = Readonly<typeof initialState>;

export const videoConsultationReducer = (
  state: VideoConsultationState = initialState,
  action,
): VideoConsultationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_WEEK_DETAIL):
    case REQUEST(ACTION_TYPES.FETCH_WEEK_DETAIL_OFFLINE):
    case REQUEST(ACTION_TYPES.FETCH_DOCTORS):
    case REQUEST(ACTION_TYPES.FETCH_APPOINTMENTS):
    case REQUEST(ACTION_TYPES.ASSIGN_DOCTOR_FOR_APPOINTMENT):
    case REQUEST(ACTION_TYPES.FETCH_CONSULTATION_DETAILS):
    case REQUEST(ACTION_TYPES.FETCH_MEDICAL_HISTORY):
    case REQUEST(ACTION_TYPES.CREATE_OFFLINE_APPOINTMENT):
    case REQUEST(ACTION_TYPES.GET_OFFLINE_APPOINTMENT):
    case REQUEST(ACTION_TYPES.EDIT_OFFLINE_APPOINTMENT):
    case REQUEST(ACTION_TYPES.GET_PET_DETAILS):
    case REQUEST(ACTION_TYPES.EDIT_PET_DETAILS):
    case REQUEST(ACTION_TYPES.SAVE_REMINDER):{
      return {
        ...state,
        payloadSaved:false
      }
    }
    case REQUEST(ACTION_TYPES.UPDATE_REMINDER):{
      return {
        ...state,
        payloadSaved:false
      }
    }
    case REQUEST(ACTION_TYPES.GET_REMINDER_TYPES):
    case REQUEST(ACTION_TYPES.GET_REMINDERS):
    case REQUEST(ACTION_TYPES.DELETE_REMINDER):
    case REQUEST(ACTION_TYPES.GET_REMINDER_ID):
    case REQUEST(ACTION_TYPES.GET_SPECIALITY):
    case REQUEST(ACTION_TYPES.GET_QUESTIONNAIRE):
    case REQUEST(ACTION_TYPES.UPLOAD_DOCUMENT):
    case REQUEST(ACTION_TYPES.CREATE_QUESTIONNAIRE):
    case REQUEST(ACTION_TYPES.EDIT_QUESTIONNAIRE):
    case REQUEST(ACTION_TYPES.DOWNLOAD_APPOINTMENT_PDF): {
      return {
        ...state,
        loading: true,
        assignDoctorResponse: {},
        initiateVideoCallResponse: {},
        petDetail: {},
        editPetDetailResponse: {},
        createAppointmentStatus: {},
        editAppointmentStatus: {},
        appointmentDownloadResponse: {}
      };
    }
    case REQUEST(ACTION_TYPES.FETCH_DOCTORS_BY_SLOT_ID): {
      return {
        ...state,
        loading: true,
        doctorsBySlot: [],
      };
    }

    case ACTION_TYPES.RESET_DATA: {
      return {
        ...state,
        appointments: [],
        medicalHistoryResponse: [],
        consultationDetails: [],
        offlineAppointmentResponse: {}
      };
    }

    case REQUEST(ACTION_TYPES.INITIATE_VIDEO_CALL): {
      return {
        ...state,
        initiateVideoCallResponse: {},
        loading: true,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_WEEK_DETAIL): {
      return {
        ...state,
        weekDetail: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_WEEK_DETAIL_OFFLINE): {
      return {
        ...state,
        weekDetailOffline: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_DOCTORS): {
      return {
        ...state,
        doctors: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_DOCTORS_BY_SLOT_ID): {
      return {
        ...state,
        doctorsBySlot: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_APPOINTMENTS): {
      return {
        ...state,
        appointments: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_CONSULTATION_DETAILS):
      return {
        ...state,
        consultationDetails: action.payload.data,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.ASSIGN_DOCTOR_FOR_APPOINTMENT):
      return {
        ...state,
        assignDoctorResponse: action.payload,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.FETCH_MEDICAL_HISTORY):
      return {
        ...state,
        medicalHistoryResponse: action.payload.data,
        loading: false,
        totalCount: action.payload.headers['x-total-count'],
      };
    case SUCCESS(ACTION_TYPES.INITIATE_VIDEO_CALL): {
      return {
        ...state,
        initiateVideoCallResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.CREATE_OFFLINE_APPOINTMENT): {
      toast.success(
        translate(
          'customer_tab.customer_appointment_details.appointment_created',
        ),
      );
      return {
        ...state,
        createOfflineAppointmentResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_OFFLINE_APPOINTMENT): {
      return {
        ...state,
        offlineAppointmentResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.EDIT_OFFLINE_APPOINTMENT): {
      toast.success(
        translate(
          'customer_tab.customer_appointment_details.appointment_edited',
        ),
      );
      return {
        ...state,
        editOfflineAppointmentResponse: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_PET_DETAILS): {
      return {
        ...state,
        petDetail: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.EDIT_PET_DETAILS): {
      return {
        ...state,
        editPetDetailResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.SAVE_REMINDER): {
      
      return {
        ...state,
        saveReminderResponse: action.payload.data,
        payloadSaved: true,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_REMINDER): {
      return {
        ...state,
        updateReminderResponse: action.payload.data,
        payloadSaved: true,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_REMINDERS): {
      return {
        ...state,
        getRemindersResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_REMINDER_TYPES): {
      return {
        ...state,
        getReminderTypesResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.DELETE_REMINDER): {
      return {
        ...state,
        deleteReminder: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.GET_REMINDER_ID): {
      return {
        ...state,
        getReminderByIdResponse: action.payload.data,
        loading: false,
      };
    }

    case SUCCESS(ACTION_TYPES.GET_SPECIALITY): {
      return {
        ...state,
        specialities: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_QUESTIONNAIRE): {
      return {
        ...state,
        questionnaireResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.UPLOAD_DOCUMENT): {
      return {
        ...state,
        uploadDocumentResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.CREATE_QUESTIONNAIRE): {
      toast.success(translate('questionnaire_saved'));
      return {
        ...state,
        createQuestionnaireResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.EDIT_QUESTIONNAIRE): {
      toast.success(translate('questionnaire_saved'));
      return {
        ...state,
        editQuestionnaireResponse: action.payload.data,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.DOWNLOAD_APPOINTMENT_PDF): {
      return {
        ...state,
        appointmentDownloadResponse: action.payload.data,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.EDIT_PET_DETAILS): {
      if (action.payload?.response?.data?.errorKey !== 'patientid.exists') {
        toast.error(translate('error.something_went_wrong'));
      }
      return {
        ...state,
        loading: false,
      };
    }

    case FAILURE(ACTION_TYPES.FETCH_WEEK_DETAIL):
    case FAILURE(ACTION_TYPES.FETCH_WEEK_DETAIL_OFFLINE):
    case FAILURE(ACTION_TYPES.FETCH_DOCTORS_BY_SLOT_ID):
    case FAILURE(ACTION_TYPES.FETCH_APPOINTMENTS):
    case FAILURE(ACTION_TYPES.INITIATE_VIDEO_CALL):
    case FAILURE(ACTION_TYPES.ASSIGN_DOCTOR_FOR_APPOINTMENT):
    case FAILURE(ACTION_TYPES.FETCH_CONSULTATION_DETAILS):
    case FAILURE(ACTION_TYPES.FETCH_MEDICAL_HISTORY):
    case FAILURE(ACTION_TYPES.CREATE_OFFLINE_APPOINTMENT):
    case FAILURE(ACTION_TYPES.GET_OFFLINE_APPOINTMENT):
    case FAILURE(ACTION_TYPES.EDIT_OFFLINE_APPOINTMENT):
    case FAILURE(ACTION_TYPES.GET_PET_DETAILS):
    case FAILURE(ACTION_TYPES.GET_SPECIALITY):
    case FAILURE(ACTION_TYPES.GET_QUESTIONNAIRE):
    case FAILURE(ACTION_TYPES.SAVE_REMINDER):{
      return {
        ...state,
        payloadSaved:false
      }
    }
    case FAILURE(ACTION_TYPES.UPDATE_REMINDER):{
      return {
        ...state,
        payloadSaved:false
      }
    }
    case FAILURE(ACTION_TYPES.GET_REMINDERS):
    case FAILURE(ACTION_TYPES.DELETE_REMINDER):
    case FAILURE(ACTION_TYPES.GET_REMINDER_ID):
    case FAILURE(ACTION_TYPES.GET_REMINDER_TYPES):
    case FAILURE(ACTION_TYPES.CREATE_QUESTIONNAIRE):
    case FAILURE(ACTION_TYPES.EDIT_QUESTIONNAIRE):
    case FAILURE(ACTION_TYPES.DOWNLOAD_APPOINTMENT_PDF): {
      toast.error(translate('error.something_went_wrong'));
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export const fetchWeekDetail: (payload) => void = payload => async dispatch => {
  let endUrl = `${API_URL}/v1/vendor/appointments/summary?date=${payload.date}`;
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_WEEK_DETAIL,
    payload: axios.get(endUrl, config),
  });
  return result;
};

export const fetchWeekDetailOffline: (payload) => void =
  payload => async dispatch => {
    let endUrl = `${API_URL}/v1/vendor/appointments/summary?date=${payload.date}`;
    if (payload?.mode === 'OFFLINE') endUrl = `${endUrl}&type=${payload.mode}`;
    const result = await dispatch({
      type: ACTION_TYPES.FETCH_WEEK_DETAIL_OFFLINE,
      payload: axios.get(endUrl, config),
    });
    return result;
  };

export const fetchDoctors: () => void = () => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_DOCTORS,
    payload: axios.get(`${API_URL}/v1/vendor/doctors`, config),
  });
  return result;
};


export const fetchMedicalHistory = (petId, page, itemsPerPage) => async (dispatch) => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_MEDICAL_HISTORY,
    payload: axios.get(
      `${API_URL}/customers/v1/customer-appointments?page=${page}&size=${itemsPerPage}&petId=${petId}&statuses=${MEDICAL_HISTORY_STATUS.COMPLETED}`,
      config,
    ),
  });
  return result;
};


export const downloadAppointment: (appointmentId) => void = appointmentId => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.DOWNLOAD_APPOINTMENT_PDF,
    payload: axios.get(
      `${API_URL}/customers/v1/appointments/${appointmentId}/details`,
      config,
    ),
  });
  return result;
};


export const fetchDoctorsBySlotId: (slotId) => void =
  slotId => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.FETCH_DOCTORS_BY_SLOT_ID,
      payload: axios.get(
        `${API_URL}/v1/vendor/doctors?slotId=${slotId}`,
        config,
      ),
    });
    return result;
  };

export const assignDoctorForAppointment: (payload) => void =
  ({ appointmentId, doctorId, unassign }) =>
  async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.ASSIGN_DOCTOR_FOR_APPOINTMENT,
      payload: axios.put(
        `${API_URL}/v1/vendor/appointments?unassign=${unassign}`,
        {
          appointmentId,
          doctorId,
        },
        config,
      ),
    });
    return result;
  };

export const fetchAppointments: (payload) => void =
  payload => async dispatch => {
    let endPoint = `${API_URL}/v1/vendor/appointments?page=0&size=500&date=${payload.date}`;
    if (payload?.doctorId !== null && payload?.doctorId != -2)
      endPoint = `${endPoint}&doctorId=${payload.doctorId}`;
    if (payload?.mode === 'ALL') endPoint = `${endPoint}&type=${payload.mode}`;
    const result = await dispatch({
      type: ACTION_TYPES.FETCH_APPOINTMENTS,
      payload: axios.get(endPoint, config),
    });
    return result;
  };

export const fetchConsultationDetails: (payload) => void =
  payload => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.FETCH_CONSULTATION_DETAILS,
      payload:
        axios.get(`${API_URL}/v1/vendor/customer/appointments/${payload.appointmentId}
      `),
    });
    return result;
  };

export const initiateVideoCall: (payload) => void =
  ({ appointmentId, callUUID }) =>
  async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.INITIATE_VIDEO_CALL,
      payload: axios.post(
        `${API_URL}/v1/vendor/appointments/${appointmentId}/call?callUUID=${callUUID}`,
        config,
      ),
    });
    return result;
  };

export const createOfflineAppointment: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_OFFLINE_APPOINTMENT,
    payload: axios.post(
      `${API_URL}/customers/v1/customer-appointments/offline`,
      entity,
      config,
    ),
  });
  return result;
};

export const getOfflineAppointment: any = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_OFFLINE_APPOINTMENT,
    payload: axios.get(
      `${API_URL}/customers/v1/customer-appointments/${id}/offline`,
      config,
    ),
  });
  return result;
};

export const editOfflineAppointment: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.EDIT_OFFLINE_APPOINTMENT,
    payload: axios.put(
      `${API_URL}/customers/v1/customer-appointments/offline`,
      entity,
      config,
    ),
  });
  return result;
};

export const getPetDetail: any = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_PET_DETAILS,
    payload: axios.get(`${API_URL}/customers/v1/customer-pets/${id}`, config),
  });
  return result;
};

export const editPetDetail: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.EDIT_PET_DETAILS,
    payload: axios.put(
      `${API_URL}/customers/v1/customer-pets/${entity?.id}`,
      entity,
      config,
    ),
  });
  return result;
};

export const saveReminder: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SAVE_REMINDER,
    payload: axios.post(
      `${API_URL}/v1/vendor/reminders`,
      entity,
      config,
    ),
  });
  return result;
};

export const updateReminder: any = (entity,id) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_REMINDER,
    payload: axios.put(
      `${API_URL}/v1/vendor/reminders/${id}`,
      entity,
      config,
    ),
  });
  return result;
};

export const getReminderById: any = (id) => async dispatch => { 
  const result = await dispatch({
    type: ACTION_TYPES.GET_REMINDER_ID,
    payload: axios.get(`${API_URL}/v1/vendor/reminders/${id}`, config),
  });
  return result;
};

export const getReminders: any = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_REMINDERS,
    payload: axios.get(`${API_URL}/v1/vendor/reminders/appointments/${id}`, config),
  });
  return result;
};

export const getReminderTypes: () => void =() => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_REMINDER_TYPES,
    payload: axios.get(`${API_URL}/v1/reminder-types`, config),
  });
  return result;
};

export const deleteReminders: any = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_REMINDER,
    payload: axios.delete(`${API_URL}/v1/vendor/reminders/${id}`, config),
  });
  return result;
};

export const getSpeciality: any = (serviceId, vendorId) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_SPECIALITY,
    payload: axios.get(
      `${API_URL}/customers/v1/services/${serviceId}/vendors/${vendorId}/specialities`,
      config,
    ),
  });
  return result;
};

export const getQuestionnaire: any = specialityId => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_QUESTIONNAIRE,
    payload: axios.get(
      `${API_URL}/customers/v1/specialities/${specialityId}/questionnaires`,
      config,
    ),
  });
  return result;
};

export const uploadDocumet: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPLOAD_DOCUMENT,
    payload: axios.post(`${API_URL}/v1/upload`, entity, config),
  });
  return result;
};

export const createQuestionnaire: any =
  (entity, appointmentId, specialityId) => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.CREATE_QUESTIONNAIRE,
      payload: axios.post(
        `${API_URL}/customers/v1/appointments/${appointmentId}/service-specialty/${specialityId}/questionnaire-response`,
        entity,
        config,
      ),
    });
    return result;
  };

export const editQuestionnaire: any =
  (entity, appointmentId, specialityId) => async dispatch => {
    const result = await dispatch({
      type: ACTION_TYPES.EDIT_QUESTIONNAIRE,
      payload: axios.put(
        `${API_URL}/customers/v1/appointments/${appointmentId}/service-specialty/${specialityId}/questionnaire-response`,
        entity,
        config,
      ),
    });
    return result;
  };

export default videoConsultationReducer;
